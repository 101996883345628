import React from 'react';
import {Button} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {sendToPooling, sendToPoolingProgressSelector} from "../../ducks/gridList";
import {useDispatch, useSelector} from "react-redux";

const PoolingButtons = ({id, row}) => {
    const {isShowSendDraftInPoolingButton, isShowSendReservationInPoolingButton, poolingUrl} = row;

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const sendToPoolingProgress = useSelector(state => sendToPoolingProgressSelector(state));
    const send = (isDraft) => {
        dispatch(sendToPooling({id, isDraft}));
    }
    const open = () => {
        window.open(`${poolingUrl}`, '_blank');
    }

    return (
        <div className='cell-buttons'>
            {
                poolingUrl && <Button
                    onClick={open}
                    size='mini'
                >
                    {t('openInPoolingButton')}
                </Button>
            }
            {
                isShowSendDraftInPoolingButton && <Button
                    onClick={() => send(true)}
                    disabled={sendToPoolingProgress}
                    loading={sendToPoolingProgress}
                    size='mini'
                >
                    {t('sendDraftToPoolingButton')}
                </Button>
            }
            {
                isShowSendReservationInPoolingButton && <Button
                    onClick={() => send()}
                    disabled={sendToPoolingProgress}
                    loading={sendToPoolingProgress}
                    size='mini'
                >
                    {t('sendReservationToPoolingButton')}
                </Button>
            }
        </div>
    );
};

export default PoolingButtons;